export default {
    data() {
        return {
            pagination: {
                pageNo: 1,
                pages: 0,
                total: 0,
                pageSize: 10,
                next: false,
            }
        }
    },
    watch: {
        pagination: {
            handler(val) {
                this.pagination.next = val?.pageNo < val?.pages;
            }
        }
    },
    methods: {
        getDefaultPagination() {
            return this.pagination = {pageNo: 1, pages: 0, total: 0, pageSize: 10, next: false}
        },
        copyPagination(obj) {
            this.pagination = Object.assign({}, obj);
            delete this.pagination.records;
        }
    }
}
