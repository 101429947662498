<template>
    <div class="coupon-info">
        <div class="info-header" :class="disabled || expired ? 'disabled' : ''" @click="onTouch">
            <!-- <div v-if="disabled" class="coupon-overlay" /> -->
            <div>
                <div class="info-title">{{couponInfo.title}}</div>
                <div class="info-name">{{couponInfo.batchName}}</div>
            </div>
            <div class="fx-row">
                <div class="fx-end fx-column">
                    <div>
                        <span class="info-unit">￥</span>
                        <span class="info-faceprice">{{couponInfo.facePrice}}</span>
                    </div>
                    <div v-if="couponInfo.startDate && couponInfo.endDate" class="info-validity">
                        <span>有效期至</span>
                        <span>{{couponInfo.endDate}}</span>
                    </div>
                </div>
                <div v-if="pick" class="fx-middle" style="margin-left:20px;">
                    <van-icon v-show="!couponInfo.isUse" :name="require('./static/un-check.png')" size="16"></van-icon>
                    <van-icon v-show="couponInfo.isUse" :name="require('./static/checked.png')" size="16"></van-icon>
                </div>
            </div>
        </div>
        <div class="info-gapline"></div>
        <div class="info-footer">
            <div v-if="disabled" class="disabled-reason">
                <div class="fx-row fx-center">
                    <van-icon :name="require('./static/info.png')" size="12" style="margin-right: 2px;"></van-icon>
                    <div class="fc-error">本单不可用原因</div>
                </div>
                <div class="fc-tips">
                    <div v-for="(item,index) in couponInfo.unUseReasonList" :key="index">
                        {{item}}
                    </div>
                </div>
            </div>
            <div v-else-if="expired" class="disabled-reason">
                <div class="fc-error">已过期</div>
            </div>
            <div v-else>
                <div v-for="(item,index) in couponInfo.instructionList" :key="index">
                    {{item}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        couponInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        pick: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    computed: {
        disabled() {
            if (this.pick && this.couponInfo.unUseReasonList.length > 0) return true;
            return false;
        },
        expired() {
            if (this.couponInfo.expired === true) return true;
            return false;
        }
    },
    methods: {
        onTouch() {
            if (!this.disabled) {
                this.$emit('on-click', this.couponInfo);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.coupon-info {
	position: relative;
	background-color: #fff;
	border-radius: 6px;
	padding-bottom: 10px;
	margin-bottom: 20px;
	overflow: hidden;

	.info-header {
		padding: 15px 15px 0px 15px;
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		line-height: 2;
		&.disabled::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(255, 255, 255, 0.6);
		}
		.coupon-overlay {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background-color: rgba(255, 255, 255, 0.6);
		}
		.info-title {
			font-size: 1.4em;
            font-weight: 700;
		}
		.info-name {
			background-color: #f5e2db;
			color: #ff4500;
			padding: 3px 5px;
			line-height: 1;
            border-radius: 4px;
		}

		.info-unit {
			color: $primary-color;
		}
		.info-faceprice {
			color: $primary-color;
			font-size: 2.8em;
			line-height: 1;
		}
		.info-validity {
			font-size: 0.7em;
			color: $tips-color;
			line-height: 1;
		}
	}
	.info-gapline {
		border: 1px dashed $border-color;
		margin: 8px 15px;
	}

	.info-footer {
		font-size: 0.8em;
		color: $tips-color;
        padding: 0 15px;
		.disabled-reason {
		}
	}
}
</style>
